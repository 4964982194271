import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import { history } from '../store';
import data from '../data/data';

import Field from '../components/field.js';

// Reducers
import {
    updateCurrentNav,
} from '../reducers/nav'

import {
    showTooltip,
} from '../reducers/tooltips'

import {
    calculateResults,
} from '../reducers/data'

class Screen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextIndex: 0,
            next: null,
            modalIsOpen : false
        }
        this.showTooltip = this.showTooltip.bind(this);
        this.validateScreen = this.validateScreen.bind(this);
        this.printScreen = this.printScreen.bind(this);
        this.stepBack = this.stepBack.bind(this);
        this.onUnload = this.onUnload.bind(this);
    }



    componentDidMount() {
        
        window.addEventListener('beforeunload', this.onUnload);
        this.props.updateCurrentNav(this.props.name);

        if (this.props.name === "results") {
            this.props.calculateResults();
        } else {
            let nextIndex = 0;
            let prevIndex = 0;
            let next = null;
            let prev = null;
            data.screens.forEach((ss, sk) => {
                if (ss.name === this.props.name) {
                    prevIndex = sk - 1;
                    nextIndex = sk + 1;
                }
            });
            if (!!data.screens[nextIndex]) next = data.screens[nextIndex];
            if (!!data.screens[prevIndex]) prev = data.screens[prevIndex];
            this.setState({
                nextIndex,
                next,
                prevIndex,
                prev
            });
        }
    }
    componentDidCatch(error, info) {
        console.log(error, info);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onUnload);
    }

    showTooltip(e) {
        e.preventDefault();
        this.setState({
            modalIsOpen : true
        })
    }
    validateScreen(e) {
        e.preventDefault();
        history.push('/' + this.state.next.name);
    }
    stepBack(e) {
        e.preventDefault();
        history.push('/' + this.state.prev.name);
    }
    printScreen(e) {
        e.preventDefault();
        window.print();
    }

    restartApp(e) {
        e.preventDefault();
        history.push('/');
    }

    onUnload(e) {
        e.preventDefault();
        history.push('/' + data.screens[0].name);
    }

    render() {
        let sections = [];
        let buttonTextPrev = null;
        let buttonTextNext = null;
        let printBtn = <button onClick={this.printScreen} className="icon-iconmonstr-printer-3 print-btn"><span>Print this Page</span></button>
        let resetApp = <button onClick={this.restartApp} className="restart-btn"><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24"><path d="M13.5 2c-5.629 0-10.212 4.436-10.475 10h-3.025l4.537 5.917 4.463-5.917h-2.975c.26-3.902 3.508-7 7.475-7 4.136 0 7.5 3.364 7.5 7.5s-3.364 7.5-7.5 7.5c-2.381 0-4.502-1.119-5.876-2.854l-1.847 2.449c1.919 2.088 4.664 3.405 7.723 3.405 5.798 0 10.5-4.702 10.5-10.5s-4.702-10.5-10.5-10.5z"/></svg><span>Restart Calculator</span></button>
        if (!!this.props.sections) {
            this.props.sections.forEach((s, k) => {
                let tooltip = null;
                let modal = null;
                let fields = [];
                if( s.tooltip ) {
                    tooltip = <div className="tooltip" data-tip data-for={s.title}><span>i</span></div>
                    modal = (
                        <ReactTooltip multiline={true} 
                            className="tooltip-box" 
                            type="info"
                            id={s.title} 
                            aria-haspopup="true" 
                            effect="solid"
                        > 
                            { s.tooltip.props.children }
                        </ReactTooltip>
                    )
                }

                if (!!s.fields) {
                    s.fields.forEach((f, fk) => {
                        fields.push(
                            <div className={"field " + f.type} key={fk}>
                                <Field {...f} />
                            </div>
                        );
                    })
                }
                sections.push(
                    
                    <div className={"section " + (!!s.className ? s.className : '') + (s.style ? s.style : "")} key={k}>
                        {!!s.title ? <h3>{s.title}{tooltip}</h3> : null}
                        {!!s.text ? s.text : null}
                        {fields}
                        {modal ? modal : null}
                    </div>
                );
            });
        }
        if (!!this.state.prev) {
            buttonTextPrev = <span><span>Back to Step {this.state.prevIndex + 1}</span><em>{this.state.prev.title}</em></span>
        }
        if (!!this.state.next) {
            buttonTextNext = this.state.next.name === 'results' ? <span>Get Results</span> : <span><span>Continue to Step {this.state.nextIndex + 1}</span><em>{this.state.next.title}</em></span>
        }
        return (
            <div className="screen-wrap">
                <div className="headline">
                    <div>
                        {!!this.props.title && !!!this.props.hideTitle ? <h2>{this.props.title}</h2> : null}
                        <div className="no-print">
                            {!!this.props.text ? this.props.text : null}
                        </div>
                    </div>
                    { 
                        this.props.name !== "results" 
                            ? null 
                            : <div className="btn-container no-print">
                                    {resetApp}
                                    {printBtn}
                              </div>
                    }
                    
                </div>
                <div className={"sections " + (!!this.props.layout ? this.props.layout : null)}>
                    {sections}
                    
                </div>
                <div className="buttons clearfix">
                    {!!this.state.prev ? <button className="btn primary prev icon-left-open-1" onClick={this.stepBack}>{buttonTextPrev}</button> : null}
                    {!!this.state.next ? <button className={"btn primary next icon-right-open-1 next-" + this.state.next.name} onClick={this.validateScreen.bind(this)}>{buttonTextNext}</button> : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => bindActionCreators({
    updateCurrentNav,
    showTooltip,
    calculateResults
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Screen);