export const ACCEPT_TERMS = 'ACCEPT_TERMS';

const initialState = {
  hasAcceptedTerms: false
};

export default (state = initialState, action = {}) => {
  switch(action.type) {
    case ACCEPT_TERMS:
      return {
        ...state,
        hasAcceptedTerms: action.bool
      };
    default: return state;
  }
}

export const acceptTerms = (bool) => {
  return dispatch => {
    dispatch({
      type: ACCEPT_TERMS,
      bool
    })
  }
}