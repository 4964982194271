import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { history } from '../store';
import data from '../data/data'

// Reducers
import { acceptTerms } from '../reducers/auth'
import { updateCurrentNav } from '../reducers/nav'

class AcceptTerms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolledTerms: false,
            hasAcceptedTerms : (sessionStorage.getItem("acceptTerms")) ? JSON.parse(sessionStorage.getItem("acceptTerms")) : null
        }
        this.scrollTerms = this.scrollTerms.bind(this);

    }
    scrollTerms() {
        this.setState({
            scrolledTerms: true
        })
    }
    componentDidMount() {
        this.props.updateCurrentNav('screen-login')
        if(this.state.hasAcceptedTerms === true) {
            this.props.acceptTerms(true);
            history.push('/' + data.screens[0].name)
        }
    }
    componentDidCatch(error, info) {
        console.log(error, info);
    }
    acceptTerms() {
        sessionStorage.setItem("acceptTerms", true);
        this.props.acceptTerms(true);
        history.push('/' + data.screens[0].name)
    }
    render() {
        return (
            <div>
                {data.terms.title}
                {data.terms.text}
                <div className="terms-box-container">
                    <div tabIndex={4} className="terms-box" onScroll={this.scrollTerms}>
                        {data.terms.terms}
                    </div>
                    <div className={"sticky-scroll icon-down-open-1" + (this.state.scrolledTerms ? ' hidden' : '')}>Scroll to read all Terms & Conditions</div>
                </div>
                <button tabIndex={5} className="btn secondary icon-right-open-1" onClick={this.acceptTerms.bind(this)}><span>I Accept the Terms & Conditions</span></button>
            </div>
        );
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
    acceptTerms,
    updateCurrentNav,
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AcceptTerms);