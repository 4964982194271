import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';


// Reducers
import { updateCurrentNav } from '../reducers/nav'

class Loading extends React.Component {

    componentDidMount() {
        this.props.updateCurrentNav('screen-login')
    }

    render(){
        return (
            <main className="loader">
                <div className="container">
                    <Loader 
                        type="Oval"
                        color="#e9bd51"
                        height={100}
                        width={100}
                    />
                </div>
            </main>
        )
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
    updateCurrentNav
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Loading)