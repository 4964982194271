export const UPDATE_CURRENT_NAV = 'UPDATE_CURRENT_NAV'
export const CHANGE_FONT_SIZE = 'CHANGE_FONT_SIZE'

const initialState = {
  currentNav: '',
  fontSize: 'small'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_NAV:
      return {
        ...state,
        currentNav: action.className
      }
    case CHANGE_FONT_SIZE:
      return {
        ...state,
        fontSize: action.fontSize
      }
    default:
      return state
  }
}

export const updateCurrentNav = (className) => {
  return dispatch => {
    dispatch({
      type: UPDATE_CURRENT_NAV,
      className: className
    })
  }
}

export const changeFontSize = (fontSize) => {
  return dispatch => {
    dispatch({
      type: CHANGE_FONT_SIZE,
      fontSize
    })
  }
}