import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';

// import PropTypes from 'prop-types';
import { history } from '../store';

import data from '../data/data';

// Reducers
import {
    changeValidation,
} from '../reducers/validation'

export function validateLastScreen(ComposedComponent, defaultProps) {
    class Validation extends React.Component {
        componentDidMount() {
            const { lastScreens, changeValidation, currentData } = this.props;

            if (lastScreens.length) for (let ls in lastScreens) {
                let lastScreen = lastScreens[ls]

                if (lastScreen.hasOwnProperty('name')) {
                    let errors = []

                    if (lastScreen.hasOwnProperty('sections')) lastScreen.sections.forEach((s) => {
                        if (s.hasOwnProperty('fields')) s.fields.forEach((f) => {
                            if (!!f.required) {
                                if (!!!currentData[f.name] || !!!currentData[f.name].value) {
                                    errors.push(f.name)
                                } else {
                                    switch (f.type) {
                                        case 'years-of-service-chart':
                                            if (!!!currentData[f.name].value.total) errors.push(f.name)
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            }
                        })
                    })

                    changeValidation({
                        [lastScreen.name]: errors
                    })

                    if (errors.length) {
                        history.push('/' + lastScreen.name)
                        break;
                    }
                }
            }
        }

        componentDidCatch(error, info) {
            console.log(error, info);
        }

        render() {
            return (
                this.props.isValid ? <ComposedComponent {...this.props} /> : null
            );
        }
    }

    Validation.defaultProps = defaultProps;

    function mapStateToProps(state, props) {
        const { name } = defaultProps;
        const { validation } = state;
        let currentScreen = {}
        let lastScreens = []
        let isValid = true

        for (let s in data.screens) {
            let screen = data.screens[s]
            if (screen.name === name) {
                currentScreen = screen;
                break
            }
            lastScreens.push(screen)
            if (!!!validation[screen.name]) {
                isValid = false
            }
        }

        return {
            isValid,
            lastScreens,
            currentScreen,
            currentData: state.data.fields
        };
    }

    const mapDispatchToProps = dispatch => bindActionCreators({
        changeValidation
    }, dispatch)

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(Validation);
}