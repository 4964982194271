import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { forIn } from 'lodash'

class YearsOfService extends React.Component {
    constructor(props) {
        super(props);
        this.getNewValue = this.getNewValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        const { options } = this.props;
        let newVal = this.props.value;

        options.forEach( (o,k) => {
            if( o.hasOwnProperty('defaultVal') && !newVal.hasOwnProperty(o.value) ) {
                newVal = this.getNewValue( o.defaultVal, o.value, newVal );
            } else if( !newVal.hasOwnProperty(o.value) ) {
                newVal = this.getNewValue( 0, o.value, newVal );
            }
        })

        this.props.onChange( newVal );
    }
    getNewValue( val, name, valObj ) {
        let newVal = Object.assign({},valObj);
        newVal[name] = parseFloat(val);

        let total = 0;
        forIn(newVal, (v,k) => {
            if( k !== "total" ) total += parseFloat(v);
        })
        newVal.total = total

        return newVal;
    }
    handleChange( e ){
        let val = e.target.value;     
        let name =  e.target.name;
        if( !val || val < 0 ) val = 0;
        let newVal = this.getNewValue( val, name, this.props.value );
        this.props.onChange( newVal );
    }
    handleFocus( e ) {
        e.target.select();
    }
    render() {
        let tableCount = document.body.clientWidth > 756 ? 2 : 1;
        let columns = [];
        let tables = [];

        for( let t = 0; t < tableCount; t++ ) {
            columns[t] = [];
            let min = this.props.options.length/tableCount * t;
            let max = this.props.options.length/tableCount * (t+1);
            this.props.options.forEach( (o,k) => {
                if( k >= min && k < max ) {
                    columns[t].push( o );
                }
            });
            if( t === tableCount-1 ) {
                columns[t].push( { 'label' : 'Total', 'value' : 'total' } );
            }
        }

        columns.forEach( (c,ck) => {
            let columnRows = [];

            c.forEach( (r,rk) => {
                columnRows.push(
                    <tr key={rk} className={r.value}><th>{r.label}</th><td><input onFocus={this.handleFocus} readOnly={r.value==="total"} id={r.value} name={r.value} value={this.props.value[r.value]} type="number" onChange={this.handleChange} step={0.1} /></td></tr>
                )
            })

            tables.push(
                <table key={ck}><thead><tr><th>Class</th><th>Years of Service</th></tr></thead><tbody>{ columnRows }</tbody></table>
            );
        });

        return (
            <div className={"columns-"+tableCount}>{tables}</div>            
        );
    }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(YearsOfService)