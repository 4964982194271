import React from 'react'
import { connect } from 'react-redux'

class Logo extends React.Component {
    render() {
    	let white = this.props.currentNav === "screen-login"
        return (
            <svg version="1.0" id="Layer_1" x="0px" y="0px" viewBox="0 0 632.6 176.4" enableBackground="new 0 0 632.6 176.4">
	            <path fill={white?"#ffffff":"#101C41"} d="M137.8,151.6l-1.5,6.4H43.6L17.2,39.8h26.5C38,46.8,36.3,53.7,40.3,59c3.4,4.6,10.3,5.8,18,4.6
	            	c0.1,0.4,0.3,0.8,0.5,1.2c1.1,1.4,2.8,1.7,4.3,0.8l7.8-4.7c-1.4,1.6-3,4.6-2.1,6.5l2.9-1.7l2.1,2.9l-3.1,1.8l2.5,3.5l3.1-1.8l3,4.3
	            	c0.5,0.6,2.3,0.8,3,0.1l16.3,22.5l21.8,29.8l-1.8,1l-2.4,0.1l-0.6-0.8l-3.5,2.4l1.1,1.4l-2.4,1.7l-1.1-1.4l-2.3,1.6l4.6,6l1.7,0
	            	l0.5-1.6l-1.9-2.5l2.4-1.7l3.1,4.1l-3.4,4.9l5.9-1.6l3.1,4.1l-2.4,1.7l-1.9-2.5l-1.7,0l-0.5,1.6l4.6,6l2.3-1.6l-1.1-1.4l2.4-1.7
	            	l1.1,1.4l3.5-2.4l-0.6-0.8l2.2-3.4l4,5.2L137.8,151.6z M62.8,56.4c2.3-1.4,3.8-3.6,4.4-5.9c2.6,0.4,5.3-0.1,7.6-1.5
	            	c4.9-3,6.1-9,2.8-13.4c-3.3-4.4-9.9-5.1-14.8-2.1c-2.3,1.4-3.8,4.1-4.4,6.4h-3.4c-1.5,0-2.9,0.2-4.3,1c-4.9,3-6.1,8.8-2.8,13.2
	            	C51.3,58.4,57.9,59.4,62.8,56.4 M196.3,62.9c5.9,0,8.2,4.8,8.2,13.3c0,9.7-3.2,13.6-8.3,13.6c-3.5,0-5.9-1-8.4-2.4V66.9
	            	C189.8,64.7,193,62.9,196.3,62.9 M198.1,97.2c10.7,0,16.6-7.8,16.6-21.1c0-12.3-5.1-20.5-15.7-20.5c-4.6,0-8.9,2-11.7,4.7v-3.6H178
	            	v53.9h9.8V94.5C190.9,96.4,194.3,97.2,198.1,97.2 M238.7,62.9c5.1,0,7.5,3.2,7.5,9.8h-16C230.7,65.9,233.4,62.9,238.7,62.9
	            	 M255.6,79.7v-5.8c0-11.1-4.8-18.3-16.9-18.3c-13.9,0-18.6,8.8-18.6,21c0,14.4,7,20.6,18.8,20.6c7.2,0,11.5-2.2,14.2-4.1l-4.9-6.7
	            	c-1.9,1.5-4.8,3-8.5,3c-5.9,0-9.1-3.8-9.4-9.7H255.6z M296,96.1V69.9c0-9.8-3.9-14.3-11.9-14.3c-4.6,0-9.2,2.4-13.2,5.8v-4.7h-9.2
	            	v39.3h9.8V69c3.2-2.7,7.1-4.9,9.8-4.9c4,0,5.1,2.3,5.1,8.9v23.1H296z M338.2,96.1V69.9c0-9.8-3.9-14.3-12-14.3
	            	c-4.5,0-9.2,2.4-13.2,5.8v-4.7h-9.1v39.3h9.8V69c3.2-2.7,7.1-4.9,9.8-4.9c4,0,5.1,2.3,5.1,8.9v23.1H338.2z M359.6,97.2
	            	c8.5,0,16.8-3.4,16.8-12.5c0-8.9-7.2-11-13.6-12.6c-5.3-1.3-8.8-2.2-8.8-5.1c0-2.7,2.4-4,6.4-4c3.6,0,6.6,1.4,8.4,4.1l7.1-4.2
	            	c-3.2-5-8.9-7.2-15.4-7.2c-7.9,0-15.9,3.4-15.9,12.2c0,9.1,7.3,11.4,15.6,13.5c4.9,1.2,6.8,2.2,6.8,4.5c0,3.3-3.9,4.2-7.3,4.2
	            	c-3.6,0-7.2-1.3-9.2-4.5l-7.1,4.3C346.4,95.1,352.9,97.2,359.6,97.2 M417.7,56.7H408l-9.8,26l-9.7-26h-10.1l15,37.8l-0.7,1.8
	            	c-2,4.7-4,6.8-7.3,6.8c-1.6,0-4.2-0.5-6.4-1.3l-2.2,7.5c2.6,0.8,6.1,1.3,8.6,1.3c7.2,0,12.4-2.7,15.8-11.4L417.7,56.7z M434,97.2
	            	c2.9,0,5.4-0.4,8.2-1.1l-2.3-7.3c-1.1,0.4-2.6,0.6-3.8,0.6c-2.5,0-3.3-1.5-3.3-6V38.4l-9.8,5.3v40.7C423.1,92.7,425.3,97.2,434,97.2
	            	 M477.2,56.7h-9.6l-8.9,26.1L450,56.7h-10.1l14.8,39.3h7.7L477.2,56.7z M503.8,85.5c-3,2.3-5.9,4.1-9,4.1c-3.3,0-5.7-1.6-5.7-5.2
	            	c0-4.5,3-6.3,9.6-7c1.2-0.2,4.2-0.5,5.1-0.5V85.5z M513.5,96.1V69.5c0-10.2-5.8-13.9-16.9-13.9c-7.1,0-12.3,1.5-15.9,4.5l4.7,6.5
	            	c2.6-2.3,5.5-3.7,10.6-3.7c4.9,0,7.7,1.5,7.7,5.9v1.6c-1.7,0.1-6.4,0.5-7.9,0.7c-9.5,0.9-16.6,3.9-16.6,13.5
	            	c0,7.4,4.8,12.6,12.4,12.6c5.4,0,9.3-2.5,12.7-5.1v4H513.5z M555.5,96.1V69.9c0-9.8-4-14.3-12-14.3c-4.5,0-9.2,2.4-13.2,5.8v-4.7
	            	h-9.2v39.3h9.8V69c3.2-2.7,7.1-4.9,9.8-4.9c4,0,5.1,2.3,5.1,8.9v23.1H555.5z M570.3,51.9c3.8,0,6-1.6,6-5.1c0-4-3-5.2-6-5.2
	            	c-3.7,0-6,1.7-6,5.2C564.3,50.1,566.4,51.9,570.3,51.9 M575.6,96.1V56.7h-16V64h6.2v32.1H575.6z M606.7,85.5c-3,2.3-5.9,4.1-9,4.1
	            	c-3.3,0-5.7-1.6-5.7-5.2c0-4.5,3-6.3,9.6-7c1.2-0.2,4.2-0.5,5.1-0.5V85.5z M616.4,96.1V69.5c0-10.2-5.7-13.9-16.9-13.9
	            	c-7.1,0-12.2,1.5-15.9,4.5l4.8,6.5c2.6-2.3,5.5-3.7,10.6-3.7c4.9,0,7.7,1.5,7.7,5.9v1.6c-1.7,0.1-6.4,0.5-7.9,0.7
	            	c-9.5,0.9-16.7,3.9-16.7,13.5c0,7.4,4.8,12.6,12.4,12.6c5.4,0,9.3-2.5,12.7-5.1v4H616.4z"/>
	            <path fill={white?"#ffffff":"#789FC6"} d="M85.3,30.8c-5.1-6.8-17.6-7.2-29.1-1.5L53.8,19h72.4l-4.7,20.8h41.3l-22.7,102.1l-34.6-47L89.6,72.5
	            	c1.3-0.9,1-2.1,1-2.1l-3.2-4.4l3.2-1.8L88,60.6l-3.1,1.8l-2.1-2.9l2.9-2c-1.6-1.5-5.4-1.5-7.5-0.9l-0.4,0.1l6.3-3.8
	            	c1.5-0.9,1.9-2.9,0.9-4.3c-0.3-0.4-0.6-0.7-1-0.9C87.9,41.8,88.7,35.4,85.3,30.8 M188.9,140.1c0-0.8-0.1-1.5-0.4-2.1
	            	c-0.3-0.6-0.7-1.1-1.2-1.4c-0.5-0.4-1.1-0.7-1.8-1c-0.7-0.3-1.5-0.5-2.4-0.7c-1.1-0.2-2-0.5-2.6-0.7c-0.6-0.2-1-0.5-1.3-0.9
	            	c-0.3-0.3-0.4-0.8-0.4-1.3c0-0.8,0.3-1.5,1-2c0.7-0.5,1.6-0.8,2.7-0.8c1.8,0,3.2,0.8,4.1,2.3l1.7-1.2c-1.2-2-3.2-3-5.9-3
	            	c-1.9,0-3.4,0.4-4.4,1.3c-1,0.8-1.5,2-1.5,3.4c0,1,0.2,1.7,0.6,2.4c0.4,0.6,1,1.1,1.9,1.5c0.9,0.4,2,0.7,3.5,1
	            	c1.4,0.3,2.4,0.7,3.1,1.2c0.7,0.5,1,1.2,1,2c0,1-0.4,1.7-1.2,2.2c-0.8,0.5-1.7,0.7-2.7,0.7c-0.6,0-1.2-0.1-1.6-0.2
	            	c-0.5-0.1-0.9-0.3-1.3-0.6c-0.4-0.3-0.7-0.5-1-0.8c-0.3-0.3-0.5-0.6-0.8-1l-1.8,1.1c0.6,1,1.4,1.8,2.4,2.4c1,0.6,2.4,0.9,4,0.9
	            	c0.9,0,1.7-0.1,2.4-0.3c0.7-0.2,1.4-0.5,1.9-0.9c0.6-0.4,1-0.9,1.3-1.5C188.7,141.7,188.9,141,188.9,140.1 M202.7,127.8h-12.9v1.9
	            	h5.3v14.9h2.2v-14.9h5.3V127.8z M206.1,138.1l2.8-8.2l2.8,8.2H206.1z M210.3,127.8h-2.8l-5.9,16.8h2.2l1.6-4.7h6.9l1.6,4.7h2.3
	            	L210.3,127.8z M228.1,127.8h-12.9v1.9h5.3v14.9h2.2v-14.9h5.3V127.8z M240.4,142.8h-8.4v-6h7.6v-1.9h-7.6v-5.1h8.4v-1.9h-10.5v16.8
	            	h10.5V142.8z M259.1,142.8h-8.4v-6h7.6v-1.9h-7.6v-5.1h8.4v-1.9h-10.5v16.8h10.5V142.8z M277,127.8h-2.9l-4.7,9.3l-4.6-9.3h-3v16.8
	            	h2.1v-14.3l4.8,9.7h1.4l4.9-9.7v14.3h2.1V127.8z M289.3,132.9c0,0.9-0.2,1.6-0.5,2.1c-0.3,0.5-0.8,0.8-1.4,1
	            	c-0.6,0.2-1.4,0.3-2.5,0.3h-2.3v-6.6h2.7c0.9,0,1.6,0.1,2.2,0.3c0.6,0.2,1,0.5,1.3,1C289.2,131.4,289.3,132.1,289.3,132.9
	            	 M291.6,132.8c0-1.8-0.5-3.1-1.5-3.8c-1-0.8-2.6-1.2-4.6-1.2h-4.9v16.8h2.1v-6.5h2.7c1.1,0,2.1-0.1,2.8-0.3c0.8-0.2,1.4-0.5,1.9-1
	            	c0.5-0.4,0.9-1,1.1-1.6C291.5,134.6,291.6,133.8,291.6,132.8 M304.1,142.8h-7.9v-14.9H294v16.8h10.1V142.8z M317.1,136.3
	            	c0,1.5-0.2,2.8-0.5,3.8c-0.3,1-0.9,1.8-1.7,2.3c-0.8,0.5-1.8,0.8-3,0.8c-0.9,0-1.7-0.2-2.4-0.5c-0.7-0.3-1.2-0.7-1.6-1.3
	            	c-0.4-0.6-0.7-1.3-0.9-2.1c-0.2-0.9-0.3-1.8-0.3-3c0-1.2,0.1-2.2,0.3-3c0.2-0.9,0.5-1.6,0.9-2.1c0.4-0.6,0.9-1,1.6-1.3
	            	c0.7-0.3,1.5-0.4,2.4-0.4c1,0,1.8,0.1,2.4,0.4c0.7,0.3,1.2,0.7,1.6,1.3c0.4,0.6,0.7,1.3,0.9,2.1C317,134.1,317.1,135.1,317.1,136.3
	            	 M319.4,136.3c0-2.9-0.6-5.1-1.7-6.6c-1.2-1.5-3.1-2.2-5.7-2.2c-1.4,0-2.5,0.2-3.5,0.6c-1,0.4-1.7,1-2.3,1.7c-0.6,0.8-1,1.7-1.3,2.8
	            	c-0.3,1.1-0.4,2.3-0.4,3.7c0,1.4,0.1,2.6,0.4,3.7c0.3,1.1,0.7,2,1.3,2.7c0.6,0.8,1.4,1.3,2.3,1.7c1,0.4,2.1,0.6,3.5,0.6
	            	c1.4,0,2.5-0.2,3.5-0.6c0.9-0.4,1.7-1,2.3-1.7c0.6-0.8,1-1.7,1.3-2.7C319.3,138.9,319.4,137.7,319.4,136.3 M331.5,127.8l-4.6,8
	            	l-4.5-8H320l5.8,10.1v6.7h2.2v-6.8l5.8-10H331.5z M346.1,142.8h-8.4v-6h7.6v-1.9h-7.6v-5.1h8.4v-1.9h-10.5v16.8h10.5V142.8z
	            	 M359.4,142.8H351v-6h7.6v-1.9H351v-5.1h8.4v-1.9h-10.5v16.8h10.5V142.8z M373.7,140.1c0-0.8-0.1-1.5-0.4-2.1
	            	c-0.3-0.6-0.7-1.1-1.2-1.4c-0.5-0.4-1.1-0.7-1.8-1c-0.7-0.3-1.5-0.5-2.4-0.7c-1.1-0.2-2-0.5-2.6-0.7c-0.6-0.2-1-0.5-1.3-0.9
	            	c-0.3-0.3-0.4-0.8-0.4-1.3c0-0.8,0.3-1.5,1-2c0.7-0.5,1.6-0.8,2.7-0.8c1.8,0,3.2,0.8,4.1,2.3l1.7-1.2c-1.2-2-3.2-3-5.9-3
	            	c-1.9,0-3.4,0.4-4.4,1.3c-1,0.8-1.5,2-1.5,3.4c0,1,0.2,1.7,0.6,2.4c0.4,0.6,1,1.1,1.9,1.5c0.9,0.4,2,0.7,3.5,1
	            	c1.4,0.3,2.4,0.7,3.1,1.2c0.7,0.5,1,1.2,1,2c0,1-0.4,1.7-1.2,2.2c-0.8,0.5-1.7,0.7-2.7,0.7c-0.6,0-1.2-0.1-1.6-0.2
	            	c-0.5-0.1-0.9-0.3-1.3-0.6c-0.4-0.3-0.7-0.5-1-0.8c-0.3-0.3-0.5-0.6-0.8-1l-1.8,1.1c0.6,1,1.4,1.8,2.4,2.4c1,0.6,2.4,0.9,4,0.9
	            	c0.9,0,1.7-0.1,2.4-0.3c0.7-0.2,1.4-0.5,1.9-0.9c0.6-0.4,1-0.9,1.3-1.5C373.6,141.7,373.7,141,373.7,140.1 M378.9,129.1
	            	c0-0.5-0.1-0.9-0.3-1.2c-0.2-0.3-0.6-0.4-1.1-0.4c-0.5,0-0.9,0.1-1.1,0.3c-0.2,0.2-0.3,0.6-0.3,1c0,0.5,0.1,0.8,0.3,1
	            	c0.2,0.2,0.5,0.3,1,0.3c0,0.1,0,0.2,0,0.4c0,0.4-0.1,0.9-0.4,1.5c-0.2,0.6-0.5,1.2-0.9,1.7h1.3c0.4-0.6,0.8-1.3,1-2.1
	            	C378.7,130.8,378.9,129.9,378.9,129.1 M396.5,132.6c0,0.6-0.1,1.1-0.2,1.5c-0.1,0.4-0.4,0.7-0.7,1c-0.3,0.2-0.7,0.4-1.2,0.5
	            	c-0.5,0.1-1.1,0.2-1.8,0.2h-3.2v-6.1h3.3c0.9,0,1.6,0.1,2.2,0.3c0.6,0.2,1,0.5,1.2,0.9C396.4,131.2,396.5,131.8,396.5,132.6
	            	 M397.9,139.1c-0.2-0.8-0.4-1.3-0.7-1.7c-0.2-0.3-0.6-0.6-1.2-0.7c0.8-0.3,1.5-0.8,1.9-1.4c0.5-0.7,0.7-1.6,0.7-2.9
	            	c0-0.9-0.1-1.6-0.4-2.2c-0.3-0.6-0.6-1.1-1.2-1.4c-0.5-0.3-1.1-0.6-1.8-0.7c-0.7-0.1-1.6-0.2-2.6-0.2h-5.5v16.8h2.1v-7.1h4.3
	            	c1.2,0,1.9,0.7,2.3,2.1l1.2,4.9h2.2L397.9,139.1z M412.3,142.8h-8.4v-6h7.6v-1.9h-7.6v-5.1h8.4v-1.9h-10.5v16.8h10.5V142.8z
	            	 M426.3,127.8h-12.9v1.9h5.3v14.9h2.2v-14.9h5.3V127.8z M433.6,143h-2.1v-13.4h2.1v-1.7h-6.4v1.7h2.1V143h-2.1v1.7h6.4V143z
	            	 M445.5,132.6c0,0.6-0.1,1.1-0.2,1.5c-0.1,0.4-0.4,0.7-0.7,1c-0.3,0.2-0.7,0.4-1.2,0.5c-0.5,0.1-1.1,0.2-1.8,0.2h-3.2v-6.1h3.3
	            	c0.9,0,1.6,0.1,2.2,0.3c0.6,0.2,1,0.5,1.2,0.9C445.4,131.2,445.5,131.8,445.5,132.6 M446.9,139.1c-0.2-0.8-0.4-1.3-0.7-1.7
	            	c-0.3-0.3-0.6-0.6-1.2-0.7c0.8-0.3,1.5-0.8,1.9-1.4c0.5-0.7,0.7-1.6,0.7-2.9c0-0.9-0.1-1.6-0.4-2.2c-0.3-0.6-0.6-1.1-1.2-1.4
	            	c-0.5-0.3-1.1-0.6-1.8-0.7c-0.7-0.1-1.6-0.2-2.6-0.2h-5.5v16.8h2.1v-7.1h4.3c1.2,0,1.9,0.7,2.3,2.1l1.2,4.9h2.2L446.9,139.1z
	            	 M461.3,142.8H453v-6h7.6v-1.9H453v-5.1h8.4v-1.9h-10.5v16.8h10.5V142.8z M479.3,127.8h-2.9l-4.7,9.3l-4.6-9.3h-3v16.8h2.1v-14.3
	            	l4.8,9.7h1.4l4.9-9.7v14.3h2.1V127.8z M493.3,142.8h-8.4v-6h7.6v-1.9h-7.6v-5.1h8.4v-1.9h-10.5v16.8h10.5V142.8z M508.7,127.8h-2.1
	            	v13.5l-7.4-13.5h-3.2v16.8h2.1v-14.8l8,14.8h2.5V127.8z M523.4,127.8h-12.9v1.9h5.3v14.9h2.2v-14.9h5.3V127.8z M542.1,140.1
	            	c0-0.8-0.1-1.5-0.4-2.1c-0.3-0.6-0.7-1.1-1.2-1.4c-0.5-0.4-1.1-0.7-1.8-1c-0.7-0.3-1.5-0.5-2.4-0.7c-1.1-0.2-2-0.5-2.6-0.7
	            	c-0.6-0.2-1-0.5-1.3-0.9c-0.3-0.3-0.4-0.8-0.4-1.3c0-0.8,0.3-1.5,1-2c0.7-0.5,1.6-0.8,2.7-0.8c1.8,0,3.2,0.8,4.1,2.3l1.7-1.2
	            	c-1.2-2-3.2-3-5.9-3c-1.9,0-3.4,0.4-4.4,1.3c-1,0.8-1.5,2-1.5,3.4c0,1,0.2,1.7,0.6,2.4c0.4,0.6,1,1.1,1.9,1.5c0.9,0.4,2,0.7,3.5,1
	            	c1.4,0.3,2.4,0.7,3.1,1.2c0.7,0.5,1,1.2,1,2c0,1-0.4,1.7-1.2,2.2c-0.8,0.5-1.7,0.7-2.7,0.7c-0.6,0-1.2-0.1-1.6-0.2
	            	c-0.5-0.1-0.9-0.3-1.3-0.6c-0.4-0.3-0.7-0.5-1-0.8c-0.3-0.3-0.5-0.6-0.8-1l-1.8,1.1c0.6,1,1.4,1.8,2.4,2.4c1,0.6,2.3,0.9,4,0.9
	            	c0.9,0,1.7-0.1,2.4-0.3c0.7-0.2,1.4-0.5,1.9-0.9c0.6-0.4,1-0.9,1.3-1.5C541.9,141.7,542.1,141,542.1,140.1 M554.5,127.8l-4.6,8
	            	l-4.5-8H543l5.8,10.1v6.7h2.2v-6.8l5.8-10H554.5z M570.1,140.1c0-0.8-0.1-1.5-0.4-2.1c-0.3-0.6-0.7-1.1-1.2-1.4
	            	c-0.5-0.4-1.1-0.7-1.8-1c-0.7-0.3-1.5-0.5-2.4-0.7c-1.1-0.2-2-0.5-2.6-0.7c-0.6-0.2-1-0.5-1.3-0.9c-0.3-0.3-0.4-0.8-0.4-1.3
	            	c0-0.8,0.3-1.5,1-2c0.7-0.5,1.6-0.8,2.7-0.8c1.8,0,3.2,0.8,4.1,2.3l1.7-1.2c-1.2-2-3.2-3-5.9-3c-1.9,0-3.4,0.4-4.4,1.3
	            	c-1,0.8-1.5,2-1.5,3.4c0,1,0.2,1.7,0.6,2.4c0.4,0.6,1,1.1,1.9,1.5c0.9,0.4,2,0.7,3.5,1c1.4,0.3,2.4,0.7,3.1,1.2c0.7,0.5,1,1.2,1,2
	            	c0,1-0.4,1.7-1.2,2.2c-0.8,0.5-1.7,0.7-2.7,0.7c-0.6,0-1.2-0.1-1.6-0.2c-0.5-0.1-0.9-0.3-1.3-0.6c-0.4-0.3-0.7-0.5-1-0.8
	            	c-0.3-0.3-0.5-0.6-0.8-1l-1.8,1.1c0.6,1,1.4,1.8,2.4,2.4c1,0.6,2.3,0.9,4,0.9c0.9,0,1.7-0.1,2.4-0.3c0.7-0.2,1.4-0.5,1.9-0.9
	            	c0.6-0.4,1-0.9,1.3-1.5C570,141.7,570.1,141,570.1,140.1 M584,127.8h-12.9v1.9h5.3v14.9h2.2v-14.9h5.3V127.8z M596.3,142.8h-8.4v-6
	            	h7.6v-1.9h-7.6v-5.1h8.4v-1.9h-10.5v16.8h10.5V142.8z M614.3,127.8h-2.9l-4.7,9.3l-4.6-9.3h-3v16.8h2.1v-14.3l4.8,9.7h1.4l4.9-9.7
	            	v14.3h2.1V127.8z"/>
	        </svg>
        );
    }
}

const mapStateToProps = state => ({
	currentNav: state.nav.currentNav
})

export default connect(
    mapStateToProps
)(Logo)