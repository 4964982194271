import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { history } from '../store';

export function requireAcceptTerms(ComposedComponent, defaultProps) {
    class AcceptTerms extends React.Component {
        constructor(props){
            super(props);
            this.state = {
                hasAcceptedTerms : (localStorage.getItem("usa_check")) ? JSON.parse(localStorage.getItem("usa_check")) : null
            }
        }
        componentDidMount() {
            if (!this.props.hasAcceptedTerms) {
                history.push('/');
            }
        }

        componentDidCatch(error, info) {
            console.log(error, info);
        }

        componentDidUpdate(nextProps) {
            if (!nextProps.hasAcceptedTerms) {
                history.push('/');
            }
        }

        render() {
            return (
                (this.props.hasAcceptedTerms || this.state.hasAcceptedTerms) ? <ComposedComponent {...this.props} /> : null

            );
        }
    }

    AcceptTerms.propTypes = {
        hasAcceptedTerms: PropTypes.bool.isRequired,
    }

    AcceptTerms.defaultProps = defaultProps;

    function mapStateToProps(state) {
        return {
            hasAcceptedTerms: state.auth.hasAcceptedTerms
        };
    }

    return connect(
        mapStateToProps
    )(AcceptTerms);
}