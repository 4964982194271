export const SHOW_TOOLTIP = 'SHOW_TOOLTIP'
export const HIDE_TOOLTIP = 'HIDE_TOOLTIP'

const initialState = {
  toolTip: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOOLTIP:
      return {
        ...state,
        toolTip: action.toolTip
      }

    case HIDE_TOOLTIP:
      return {
        ...state,
        toolTip: ''
      }

    default:
      return state
  }
}

export const showTooltip = (toolTip) => {
  return dispatch => {
    dispatch({
      type: SHOW_TOOLTIP,
      toolTip
    })
  }
}

export const hideTooltip = () => {
  return dispatch => {
    dispatch({
      type: HIDE_TOOLTIP
    })
  }
}