import React from 'react';
import moment from 'moment'
import data from './hardcodedFactors.json';

export const UPDATE_DATA = 'UPDATE_DATA'
export const CALCULATE_RESULTS = 'CALCULATE_RESULTS'
export const CLEAR_STATE = 'CLEAR_STATE'

const initialState = {
  fields: {},
  results: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DATA:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.fields
        }
      }

    case CLEAR_STATE:
      return {
        ...state,
        fields : {
          ...initialState.fields,
          ...action.fields
        }
      }

    case CALCULATE_RESULTS:
      return {
        ...state,
        results: action.results
      }

    default:
      return state
  }
}

export const updateData = (key,value,type) => {
  return dispatch => {
    let fields = {
      [key]: {
        type,
        value
      }
    }
    dispatch({
      type: UPDATE_DATA,
      fields
    })
  }
}

export const calculateResults = () => {
  return (dispatch, getState) => {
    const fields = getState().data.fields
    let results = {}

    // Step 1: Get Ages
    function calculateAgeOnDate(date, dob) {
      const calcAge = date.year() - dob.year() + ((date.month() + 1) - (dob.month() + 1)) / 12 + ((date.date() + 1) - (dob.date() +  1)) / 360;
      return calcAge;
    }

    function roundNum(num, numPlaces = 6) {
      return parseFloat(Number.parseFloat(num).toFixed(numPlaces));
      // return Math.round(num * 100000) / 100000
    }

    var formatter = new Intl.NumberFormat('en-US', {
      style : 'currency',
      currency : 'USD',
    });

    let ageOnDateOfHire = calculateAgeOnDate(moment( fields.hire_date.value ), moment( fields.birthdate.value ));
    let ageOnDateOfRetirement = calculateAgeOnDate(moment(fields.date_of_retirement.value), moment(fields.birthdate.value));
    let totalService = ageOnDateOfRetirement - ageOnDateOfHire;
    let currentSalary = parseFloat(fields.current_annual_salary.value.replace(/,/g, ''));
    
    //base factors

    const asg = fields.ann_salary_growth.value;
    const aig = fields.ann_investment_growth.value;

    let periodTable = []

    for(let i=0;i<=80;i++) {

      const prevData = periodTable[i-1];
      
      let tableData = {
        period : i, 
        retirementYears : null,
        payProjection : null,
        onePercentStatutory : null,
        onePercentGrowth : null
      }

      tableData.retirementYears = Math.max(0, (Math.min(1, totalService- i )))

      if(!prevData) {
        tableData.payProjection = currentSalary;
        tableData.onePercentStatutory = 0.01* tableData.retirementYears * tableData.payProjection * ((Math.pow(1+0.04, (tableData.retirementYears/2))))
        tableData.onePercentGrowth = 0.01* tableData.retirementYears * tableData.payProjection * ((Math.pow((1 + aig / 100), (tableData.retirementYears/2))))
      } else {
        tableData.payProjection = prevData.payProjection*(1+asg/100);
        tableData.onePercentStatutory = 0.01* tableData.retirementYears * tableData.payProjection * ((Math.pow(1+0.04, (tableData.retirementYears/2)))) + prevData.onePercentStatutory*(Math.pow((1+0.04), tableData.retirementYears))
        tableData.onePercentGrowth = 0.01*tableData.retirementYears*tableData.payProjection*((Math.pow((1+aig/100),(tableData.retirementYears/2))))+prevData.onePercentGrowth*((Math.pow((1+aig/100),(tableData.retirementYears))))
      }

      periodTable.push(tableData);
    }

    let finalAverageFiveAdjustment = roundNum((1+1/(1+asg/100)+1/Math.pow((1+asg/100), 2)+1/Math.pow((1+asg/100),3) +1/Math.pow((1+asg/100), 4))/5)
    let interestAdjToAge67 = roundNum(Math.pow((1+aig/100), Math.max(0, (67- ageOnDateOfRetirement))));
    let satInterestAdjTo67 = roundNum(Math.pow(1.04, Math.max(0, (67-ageOnDateOfRetirement))));
    let fasAtRetirement = (Math.pow((1+asg/100), (Math.max(0,totalService -1))))*currentSalary*finalAverageFiveAdjustment;
    
    let accrualRate = {
      a5 : 0.012500,
      a6 : 0.010000,
      investment : 0
    }

    

    function vlookupCalc(returnValue) {
      let vlookup = {
        'retirementAge' : null,
        'age67' : null
      };
      let ageOfRetireFloor = Math.floor(ageOnDateOfRetirement);
      let ageOfRetireAddOne = ageOfRetireFloor + 1;
      let difference = ageOnDateOfRetirement - ageOfRetireFloor;

      let vlookup1 = null;
      let vlookup2 = null;
      let age67 = null

      data.map((d) => {
        if(ageOfRetireFloor === d['Age']){
          vlookup1 = d[returnValue];
        }
        if(ageOfRetireAddOne === d['Age']) {
          vlookup2 = d[returnValue]
        } 
        if(d['Age'] === 67) {
          age67 = d[returnValue]
        }
        return 0
      })

      vlookup.retirementAge = roundNum((difference*vlookup2+(1-difference)*vlookup1),5);
      vlookup.age67 = age67;

      return vlookup;
    }

    const dateOfRetirementErfBaseFactors = () => {
      let yosEligibleA5 = ((totalService >= 25) && (ageOnDateOfRetirement >= 57)) ? 1 : 0;
      let yosEligibleA6 = ((totalService >= 25) && (ageOnDateOfRetirement >= 62)) ? 2 : 0;

      let ageOfRetireFloor = Math.floor(ageOnDateOfRetirement);
      let ageOfRetireAddOne = ageOfRetireFloor + 1;
      let difference = ageOnDateOfRetirement - ageOfRetireFloor;
      let ruleOf97 = ((ageOfRetireFloor + Math.floor(totalService) >= 97) && (totalService >= 35)) ? 1 : 0

      let vlookupA51 = null;
      let vlookupA52 = null;
      let vlookupA61 = null;
      let vlookupA62 = null;


      function getColumn(num) {
        let columnName;
        switch(num) {
          case 2:
            columnName = "A-5slashA-6";
            break;
          case 3:
            columnName = "A-5";
            break;
          case 4:
            columnName = "A-6";
            break;
          default :
        }
        return columnName
      }

      let vlookup = {
        'a5_early_retirement' : null,
        'a6_early_retirement' : null
      };

      data.map((d) => {
        if(ageOfRetireFloor === d['Age']){
          vlookupA51 = d[getColumn(2 + yosEligibleA5)];
        }
        if(ageOfRetireAddOne === d['Age']) {
          vlookupA52 = d[getColumn(2 + yosEligibleA5)]
        }
        if(ageOfRetireFloor === d['Age']){
          vlookupA61 = d[getColumn(2 + yosEligibleA6)];
        }
        if(ageOfRetireAddOne === d['Age']) {
          vlookupA62 = d[getColumn(2 + yosEligibleA6)]
        }  
        return 0
      })

      vlookup.a5_early_retirement = Math.max(roundNum((difference*vlookupA52+(1-difference)*vlookupA51), 5), ruleOf97);
      vlookup.a6_early_retirement = Math.max(roundNum((difference*vlookupA62+(1-difference)*vlookupA61), 6), ruleOf97);

      return vlookup;

    }

    let statutory_msla_pv_factor = vlookupCalc('Statutory MSLA');
    let option_1_factor = vlookupCalc('Option 1 Factor');
    let cost_neutral_factor = vlookupCalc('Cost Neutral MSLA');
    let dateOfRetirementERF = dateOfRetirementErfBaseFactors();

    let accrualRateAtRet_noErf = {
      a5 : fasAtRetirement * accrualRate.a5 * totalService,
      a6 : fasAtRetirement * accrualRate.a6 * totalService,
      investment : 0
    }

    let accrualRateAtRet_includingErf = {
      a5 : accrualRateAtRet_noErf.a5 * dateOfRetirementERF.a5_early_retirement,
      a6 : accrualRateAtRet_noErf.a6 * dateOfRetirementERF.a6_early_retirement
    }

    // console.log("***********************IT STARTS HERE*******************************")
    // console.log("_______________________BASE FACTORS 1_________________________________")
    // console.log("Age at Hire :", ageOnDateOfHire)
    // console.log("Age at Retirement : ", ageOnDateOfRetirement)
    // console.log("Total Service : ", totalService)

    // console.log("_______________________BASE FACTORS 2_________________________________")

    // console.log("Final Average 5 Adjustment : ", finalAverageFiveAdjustment);
    // console.log("Intrest Adjustment To Age 67 : ", interestAdjToAge67);
    // console.log("Statutory Interest Adjustment to Age 67 : ", satInterestAdjTo67 )

    // console.log("_______________________BASE FACTORS 3_________________________________")

    // console.log("Current Salary : ", currentSalary )
    // console.log("FAS at Retirement : ", fasAtRetirement)

    // console.log("_______________________BASE FACTORS 4_________________________________")

    // // console.log('Accrual Rate : ', accrualRate)
    // console.log('Accrual at Retirement (no ERF) : ', accrualRateAtRet_noErf)
    // console.log('Accrual at Retirement (including ERF) : ', accrualRateAtRet_includingErf)

    // console.log("_______________________BASE FACTORS SALARY TABLE_________________________________")
    // console.log('period table', periodTable)

    // console.log("_______________________Statutory MSLA PV Factor Section_________________________________")
    // console.log('statutory_msla_pv_factor = ', statutory_msla_pv_factor);

    // console.log("_______________________Option 1 Factor Section_________________________________")
    // console.log('option_1_factor = ', option_1_factor);

    // console.log("_______________________Cost Neutral PV Factor Section_________________________________")
    // console.log('cost_neutral_factor = ', cost_neutral_factor);

    // console.log("_______________________Date of Retirement ERF Section_________________________________")
    // console.log('dateOfRetirementErf = ', dateOfRetirementErfBaseFactors());

    

    // // Step 10: Create Results Tables

    //*********************************************************************************
    //*********************************************************************************
    //                  START Contributions Toward Your Benefit TABLE               ***
    //*********************************************************************************
    //*********************************************************************************


    const firstYear = [
      {
        label:  "Your Employer's Annual Contributions",
        type:   'header extend'
      },
      {
        label:  'Toward Your Pension Plan',
        span : 2,
        data:   {
          a5:         "Calculated Annually",
          a6:         "Calculated Annually",
          investment: "N/A"
        }
      },
      {
        label:  'Toward Your Investment Plan',
        data:   {
          a5:         currentSalary * 0.0225,
          a6:         currentSalary * 0.02,
          investment: currentSalary * 0.035
        }
      },
      {
        label:  'Your Annual Contributions',
        type:   'header extend'
      },
      {
        label:  'Toward Your Pension Plan',
        // type:   'inset1',
        data:   {
          a5:         currentSalary * 0.05,
          a6:         currentSalary * 0.04,
          investment: "N/A"
        }
      },
      {
        label:  'Toward Your Investment Plan',
        data:   {
          a5:         currentSalary * 0.0325,
          a6:         currentSalary * 0.035,
          investment: currentSalary * 0.075, 
        }
      },
      {
        label:  'Your Annual Total',
        data:   {
          
          a5:         (currentSalary * 0.05) + (currentSalary * 0.0325),   //( currentSalary * 0.05 ) + ( (currentSalary - 0.05) * currentSalary ),
          a6:         (currentSalary * 0.04) + (currentSalary * 0.035),  //( finalAvgToAnnual * 0.04 ) + ( (curCon - 0.04) * finalAvgToAnnual ),
          investment: 0 + (currentSalary * 0.075) 
        }
      }
    ]

    let MSLA = {
      a5: accrualRateAtRet_includingErf.a5/12,
      a6 : accrualRateAtRet_includingErf.a6/12
    }

    let totalInvestmentBalance = {
      a5:         (0.0325 + 0.0225)*100*periodTable[80].onePercentGrowth,
      a6:         (0.035 + 0.02)*100*periodTable[80].onePercentGrowth,
      investment: (0.075 + 0.035)*100*periodTable[80].onePercentGrowth
    }

    let contributionsAndInterest = {
      a5 : 5 * periodTable[80].onePercentStatutory,
      a6 : 4 * periodTable[80].onePercentStatutory
    }

    let adjustedMSLA = {
      a5 : MSLA.a5-(contributionsAndInterest.a5/cost_neutral_factor.retirementAge)/12,
      a6 : MSLA.a6-(contributionsAndInterest.a6/cost_neutral_factor.retirementAge)/12,
    }

    //*********************************************************************************
    //*********************************************************************************
    //                  START DATE OF RETRIREMENT TABLE                             ***
    //*********************************************************************************
    //*********************************************************************************

    const dateOfRetirementEstimates = [
      {
        label:  'Investment Plan Account Balance',
        dateofRetirement: moment(fields.date_of_retirement.value).calendar(),
        data:   {
          a5:         totalInvestmentBalance.a5,
          a6:         totalInvestmentBalance.a6,
          investment: totalInvestmentBalance.investment
        }
      },
      {
        label:  'Pension Plan Initial Present Value',
        data:   {
          a5:         (MSLA.a5) * 12 * statutory_msla_pv_factor.retirementAge,
          a6:         (MSLA.a6) * 12 * statutory_msla_pv_factor.retirementAge,
          investment: "N/A"
        }
      },
      {
        label:  'Pension Plan Contribution & Interest',
        data:   {
          
          a5:         contributionsAndInterest.a5,
          a6:         contributionsAndInterest.a6,
          investment: "N/A"
        }
      },
      {
        label:  "Pension Plan Payment Options",
        type:   'header extend'
      },
      {
        label: <p className="nm">MSLA<br/><span className="small">Maximum Single Life Annuity <br/>Monthly Amount for Life</span></p>,
        tooltip_id : "i1",
        tooltip : <p>The <strong>Maximum Single Life Annuity</strong> option provides the highest monthly payment for as long as you live. Payments stop when you die.  If you do not withdraw your contributions and interest at retirement, the balance of that amount – minus any payments that were made to you – will be paid to your beneficiaries.</p>,
        data:   {
          a5:         MSLA.a5,
          a6:         MSLA.a6,
          investment: "N/A", 
        }
      },
      {
        label:  'Option 1',
        tooltip_id : "i2",
        tooltip : <p><strong>Option 1</strong> provides a smaller monthly payment for as long as you live. Payments stop when you die. If the sum of all payments SERS makes to you is less than the initial present value of your SERS benefit at the time you retired, then the difference – or “remaining value” – will be paid to your beneficiaries.</p>,
        type:   'inset1',
        data:   {
          
          a5:         MSLA.a5 * option_1_factor.retirementAge,
          a6:         MSLA.a6 * option_1_factor.retirementAge,
          investment: "N/A"
        }
      },
      {
        label:  'Initial Present Value',
        type:   'inset2',
        data:   {
          
          a5:         (MSLA.a5) * 12 * statutory_msla_pv_factor.retirementAge,
          a6:         (MSLA.a6) * 12 * statutory_msla_pv_factor.retirementAge,
          investment: "N/A"
        }
      },
      {
        label:  (
            <div>
              <p>
                Pension Plan Payment Options Adjusted for <br />
                Full Withdrawal of Your Contributions & Interest
              </p>
              <p className="regular">
                When you retire, you have the option to withdrawal any portion of the contributions you made toward your pension and the interest you earned on those contributions during your commonwealth career. Doing so reduces your monthly pension payments. The following “adjusted” amounts reflect the monthly pension payments after a full withdrawal of your contributions. 
              </p>
            </div>
        ),
        type:   'header extend'
      },
      {
        label: <p className="nm">Adjusted MSLA<br/><span className="small">Maximum Single Life Annuity <br/>Monthly Amount for Life</span></p>,
        data:   {
          
          a5:         adjustedMSLA.a5,
          a6:         adjustedMSLA.a6,
          investment: "N/A"
        }
      },
      {
        label:  'Adjusted Option 1',
        type:   'inset1',
        data:   {
          
          a5:         adjustedMSLA.a5 * option_1_factor.retirementAge,
          a6:         adjustedMSLA.a6 * option_1_factor.retirementAge,
          investment: "N/A"
        }
      },
      {
        label:  'Adjusted Initial Present Value',
        tooltip_id : "i3",
        tooltip : <p><strong>Adjusted Initial Present Value</strong> is the initial present value of the pension benefit minus the total value of contributions and interest.</p>,
        type:   'inset2',
        data:   {
          
          a5:         adjustedMSLA.a5 * 12 * statutory_msla_pv_factor.retirementAge,
          a6:         adjustedMSLA.a6 * 12 * statutory_msla_pv_factor.retirementAge,
          investment: "N/A"
        }
      },

    //*********************************************************************************
    //*********************************************************************************
    //                  START Deffered to Age 67 Estimates TABLE                    ***
    //*********************************************************************************
    //*********************************************************************************

    ]

    let defferedMSLA = {
      a5: accrualRateAtRet_noErf.a5/12,
      a6 : accrualRateAtRet_noErf.a6/12
    }

    let defferedContributionsAndInterest = {
      a5 : contributionsAndInterest.a5 * satInterestAdjTo67,
      a6 : contributionsAndInterest.a6 * satInterestAdjTo67
    }

    let defferedAdjustedMSLA = {
      a5 : defferedMSLA.a5-(defferedContributionsAndInterest.a5/cost_neutral_factor.age67)/12,
      a6 : defferedMSLA.a6-(defferedContributionsAndInterest.a6/cost_neutral_factor.age67)/12,
    }

    const defferedToAge67Estimates = (ageOnDateOfRetirement > 67) ? false : [
      {
        label:  'Investment Plan Account Balance',
        data:   {
          a5:         totalInvestmentBalance.a5 * interestAdjToAge67,
          a6:         totalInvestmentBalance.a6 * interestAdjToAge67,
          investment: totalInvestmentBalance.investment * interestAdjToAge67
        }
      },
      {
        label:  'Pension Plan Initial Present Value',
        data:   {
          a5:         (defferedMSLA.a5) * 12 * statutory_msla_pv_factor.age67,
          a6:         (defferedMSLA.a6) * 12 * statutory_msla_pv_factor.age67,
          investment: "N/A"
        }
      },
      {
        label:  'Pension Plan Contributions & Interest',
        data:   {
          
          a5:         defferedContributionsAndInterest.a5,
          a6:         defferedContributionsAndInterest.a6,
          investment: "N/A"
        }
      },
      {
        label:  "Pension Plan Payment Options",
        type:   'header extend'
      },
      {
        label: <p className="nm">MSLA<br/><span className="small">Maximum Single Life Annuity <br/>Monthly Amount for Life</span></p>,
        tooltip_id : "i1",
        tooltip : <p>The <strong>Maximum Single Life Annuity</strong> option provides the highest monthly payment for as long as you live. Payments stop when you die.  If you do not withdraw your contributions and interest at retirement, the balance of that amount – minus any payments that were made to you – will be paid to your beneficiaries.</p>,
        data:   {
          a5:         defferedMSLA.a5,
          a6:         defferedMSLA.a6,
          investment: "N/A"
        }
      },
      {
        label:  'Option 1',
        tooltip_id : "i2",
        tooltip : <p><strong>Option 1</strong> provides a smaller monthly payment for as long as you live. Payments stop when you die. If the sum of all payments SERS makes to you is less than the initial present value of your SERS benefit at the time you retired, then the difference – or “remaining value” – will be paid to your beneficiaries.</p>,
        type:   'inset1',
        data:   {
          
          a5:         defferedMSLA.a5 * option_1_factor.age67,
          a6:         defferedMSLA.a6 * option_1_factor.age67,
          investment: "N/A"
        }
      },
      {
        label:  'Initial Present Value',
        type:   'inset2',
        data:   {
          a5:         (defferedMSLA.a5) * 12 * statutory_msla_pv_factor.age67,
          a6:         (defferedMSLA.a6) * 12 * statutory_msla_pv_factor.age67,
          investment: "N/A"
        }
      },
      
      {
        label:  (
          <div>
            <p>
              Pension Plan Payment Options Adjusted for <br />
              Full Withdrawal of Your Contributions & Interest
            </p>
            <p className="regular">
              When you retire, you have the option to withdrawal any portion of the contributions you made toward your pension and the interest you earned on those contributions during your commonwealth career. Doing so reduces your monthly pension payments. The following “adjusted” amounts reflect the monthly pension payments after a full withdrawal of your contributions. 
            </p>
          </div>
      ),
        type:   'header extend'
      },
      {
        label: <p className="nm">Adjusted MSLA<br/><span className="small">Maximum Single Life Annuity <br/>Monthly Amount for Life</span></p>,
        data:   {
          
          a5:         defferedAdjustedMSLA.a5,
          a6:         defferedAdjustedMSLA.a6,
          investment: "N/A"
        }
      },
      {
        label:  'Adjusted Option 1',
        type:   'inset1',
        data:   {
          
          a5:         defferedAdjustedMSLA.a5 * option_1_factor.age67,
          a6:         defferedAdjustedMSLA.a6 * option_1_factor.age67,
          investment: "N/A"
        }
      },
      {
        label:  'Adjusted Initial Present Value',
        type:   'inset2',
        tooltip_id : "i3",
        tooltip : <p><strong>Adjusted Initial Present Value</strong> is the initial present value of the pension benefit minus the total value of contributions and interest.</p>,
        data:   {
          
          a5:         defferedAdjustedMSLA.a5 * 12 * statutory_msla_pv_factor.age67,
          a6:         defferedAdjustedMSLA.a6 * 12 * statutory_msla_pv_factor.age67,
          investment: "N/A"
        }
      },

    ]

    

    results = {
      firstYear,
      dateOfRetirementEstimates,
      defferedToAge67Estimates
    }

    dispatch({
      type: CALCULATE_RESULTS,
      results
    })
  }
}
