import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import cookie from 'react-cookies';
import './App.scss';

//utils
import { requireAcceptTerms } from './utils/requireAcceptTerms';
import { validateLastScreen } from './utils/validateLastScreen';
import StatusBar from './components/statusBar';

//screens
import AcceptTerms from './screens/acceptTerms';
import Screen from './screens/screen';
import GeoErrorMessage from './screens/geoErrorMessage';
import Loading from './screens/loading';

import data from './data/data';

import Logo from './images/pasers-logo';

//reducers
import {
    changeFontSize,
} from './reducers/nav';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cookieNotice: !cookie.load('acceptedCookies'),
            loading: true,
            lat: null,
            long: null,
            usa: {
                top: 49.3457868,
                bottom: 24.7433195,
                left: -124.7844079,
                right: 49.3457868
            },
            usa_check: true,
            geolocationErr: null,
        }
        this.changeFontSize = this.changeFontSize.bind(this);
        this.removeCookieNotice = this.removeCookieNotice.bind(this);
    }

    geoLocationCheck() {
        const top = this.state.usa.top;
        const bottom = this.state.usa.bottom;
        const left = this.state.usa.left;
        const right = this.state.usa.right;
        
        if(this.state.usa_check) {
            this.setState({
                usa_check : true,
                loading: false
            })
        } else {
            navigator.geolocation.getCurrentPosition(position => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                if (position) {
                    this.setState({
                        loading: false
                    })
                }
                if ((lng >= left && lng <= right) && (lat <= top && lat >= bottom)) {
                    this.setState({
                        usa_check: true,
                    })
                    sessionStorage.setItem("usa_check", true);
                } else {
                    this.setState({
                        usa_check: false,
                    })
                    sessionStorage.setItem("usa_check", false);
                }
            }, err => {
                if (err.code === 1) {
                    this.setState({
                        geolocationErr: data.options.geoLocationErrorOne,
                        loading: false,
                    })
                } else {
                    this.setState({
                        geolocationErr: data.options.geoLocationOther + `<div>Error code : ${err.code}</div>`,
                        loading: false
                    })
                }
            })
        }
        
    }

    changeFontSize(e, fontSize) {
        e.preventDefault()
        this.props.changeFontSize(fontSize)
    }

    removeCookieNotice() {
        this.setState({
            cookieNotice: false
        }, () => {
            cookie.save('acceptedCookies', true, { path: '/', expires: moment().add(14, 'days').toDate() })
        })
    }

    componentDidMount() {
        this.geoLocationCheck();

    }

    render() {
        let screens = [];
        let screenNames = [];
        const year = moment().format('YYYY');

        data.screens.forEach((s, index) => {
            screenNames.push(s.name);
            screens.push(<Route exact path={`/${s.name}`} key={index} component={requireAcceptTerms(validateLastScreen(Screen, s))} />);
        });
        return (

            <div className={`${this.props.currentNav} font-size-${this.props.fontSize}`} >
                <header>
                    {
                        this.state.cookieNotice ? 
                            <div className="cookie-notice">
                                <div className="container">
                                    <div className="message">{data.terms.cookie_notice}</div>
                                    <div className="icon-cancel" onClick={this.removeCookieNotice}></div>
                                </div>
                            </div>
                        : null
                    }
                    <div className="container">
                        <div className="logo">
                            <Logo {...this.props} />
                            <span>Pennsylvania State Employees' Retirement System</span>                            
                        </div>
                        <div className="links">
                            <div className="font-sizer">
                                <a href="/" onClick={(e) => this.changeFontSize(e,'small')} className={"small" + ( this.props.fontSize==='small' ? ' current' : '' )}>A</a>
                                <a href="/" onClick={(e) => this.changeFontSize(e,'medium')} className={"medium" + ( this.props.fontSize==='medium' ? ' current' : '' )}>A</a>
                                <a href="/" onClick={(e) => this.changeFontSize(e,'large')} className={"large" + ( this.props.fontSize==='large' ? ' current' : '' )}>A</a>
                            </div>
                            {/*<Link to="/faq" className="btn secondary short"><span>FAQ</span></Link>*/}
                        </div>
                    </div>
                </header>

                {!!screenNames && screenNames.indexOf(this.props.currentNav) >= 0 ? <StatusBar {...this.props} /> : null}

                {this.state.loading ? <Loading /> : this.state.usa_check ?
                    <main>
                        <div className="container">
                            <Switch>
                                <Route exact path="/" component={AcceptTerms} />
                                {screens}
                            </Switch>
                        </div>
                    </main> :
                    <GeoErrorMessage message={(this.state.geolocationErr) ? this.state.geolocationErr : data.options.geolocationMessage} />
                }

                <footer className="no-print">
                    <div className="container">
                        <div className="left">
                            &copy; {year} Korn Ferry. All Rights Reserved.
                        </div>
                        <div className="right">
                            <a href="https://www.kornferry.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                            <a href="https://www.kornferry.com/privacy" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                            <a href="https://www.pa.gov/design-standards/accessibility-checklist" target="_blank" rel="noopener noreferrer">Accessibility</a>
                            <a href="https://www.kornferry.com/privacy" target="_blank" rel="noopener noreferrer">Help</a>
                        </div>
                    </div>
                </footer>

            </div >
        );
    }

}

export const mapStateToProps = state => ({
    user: state.auth.user,
    currentNav: state.nav.currentNav,
    fontSize: state.nav.fontSize
});

export const mapDispatchToProps = dispatch => bindActionCreators({
    changeFontSize
}, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App));

