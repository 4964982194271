import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history';
import createRootReducers from './reducers'

export const history = createBrowserHistory()

const analytics = () => next => action => {
    let dataLayer = window.dataLayer || [];
    dataLayer.push({
        event: action.type,
        payload: action
    });
    return next(action);
};

const initialState = {}
const enhancers = []
const middleware = [
    analytics,
    thunk,
    routerMiddleware(history)
]

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.devToolsExtension

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
)

const store = createStore(
    createRootReducers(history),
    initialState,
    composedEnhancers
)



export default store
