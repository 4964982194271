import { combineReducers } from 'redux'
import auth from './auth'
import nav from './nav'
import tooltips from './tooltips'
import data from './data'
import validation from './validation'
import memberTabs from './memberTabs'
import { connectRouter } from 'connected-react-router'

const createRootReducers = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  nav,
  tooltips,
  data,
  validation,
  memberTabs

})

export default createRootReducers;