import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


// Reducers
import { updateCurrentNav } from '../reducers/nav'

class GeoErrorMessage extends React.Component {
    

    componentDidMount() {
        this.props.updateCurrentNav('screen-login')
    }

    render() {
        return (
            <main className="error-message">
                <div className="container">
                    {this.props.message}
                </div>
            </main>
        )
    }

}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
    updateCurrentNav
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeoErrorMessage)