import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Reducers
import {
  switchTab,
} from '../../reducers/memberTabs' 

class MemberResultTabs extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange( e, val ){
        e.preventDefault();
        this.props.switchTab( val );
    }
    render() {
        console.log("this.props", this.props)
        const { options, currentTab, data } = this.props;
        let tabs = [];
        options.forEach( (val,key) => {
            if( val.label === 'or' ) {
                if( tabs.length > 1 ) tabs.push(
                    <span key={key} className="or">or</span>
                )
            } else {
                if( data[val.value] !== false ) {
                    if( val.value || (val.value === '' && tabs.length > 1) ) tabs.push(
                        <a key={key} className={'tab ' + (val.value === currentTab ? 'current' : '')} href="/" onClick={(e) => this.handleChange( e, val.value )}>{val.label}</a>
                    )
                }
            }
        })
        if( tabs.length === 1 ) {
            return null
        } else {
            return (
                <div className={"tabs tabs-"+(tabs.length-1)}>{tabs}</div>            
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => ({
    currentTab: state.memberTabs.tab,
    data: state.data.results
})

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    switchTab
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberResultTabs)