import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';

import store, { history } from './store';
import App from './App';

ReactGA.initialize('UA-23139783-1');
ReactGA.plugin.require('linkid');
ReactGA.plugin.require('displayfeatures');
ReactGA.pageview(window.location.pathname + window.location.search);

const root = document.querySelector('#root')

ReactDOM.render(
    <Provider store={store}>
        <Router basename="/" history={history}>
            <div>
                <App />
            </div>
        </Router>
    </Provider>,
    root
)