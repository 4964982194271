import React from 'react'
import ReactTooltip from "react-tooltip";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class MemberResultsTable extends React.Component {
    constructor(props) {
        super(props);
        this.formatData = this.formatData.bind(this);



    }
    addCommas( val ){
        val += '';
        let x = val.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1,$2');
        }
        return x1 + x2;
    }
    formatData( row, data ) {
        if( !row.hasOwnProperty('data') ) return ""
        let val = row.data[data]
        if( typeof val === "string" ) return val
        if( row.hasOwnProperty('dollars') && row.dollars ) {
            val = Math.round( val )
        } else {
            val = val.toFixed(2)
        }
        if( val <= 0 ) val = '0.00'
        return "$" + this.addCommas( val )
    }
    render() {
        const { display, label, data, disclaimer, name } = this.props;
        if( !display || !data ) return null;

        let newLabel = data[0].dateofRetirement ? label.replace("DATEISHERE", data[0].dateofRetirement) : label
        
        let rows = [];
        data.forEach( (row,rk) => {   
            let style = {
                display: row.span ? "none" : "table-cell"
            }
            if( !!row.type && row.type.indexOf('extend') > -1 ) {
                rows.push(                        
                    <tr key={rk} className={!!row.type ? row.type : ''}>
                        <th colSpan={5}>{row.label}</th>
                    </tr>
                );
            } else {
                rows.push(              
                    <tr key={rk} className={!!row.type ? row.type : ''}>
                        <th >
                            {row.label}
                            {(row.sup) ? row.sup : null}
                            {
                                (row.tooltip) ? (
                                    <>
                                        <div className="tooltip" data-tip data-for={row.tooltip_id}><span>i</span></div>
                                        <ReactTooltip multiline={true} 
                                            className="tooltip-box" 
                                            type="info"
                                            id={row.tooltip_id} 
                                            aria-haspopup="true" 
                                            effect="solid"
                                        > 
                                            {row.tooltip}
                                        </ReactTooltip>
                                    </>
                                ) : null
                            }
                        </th>
                        <td colSpan={(row.span) ? row.span : "1"}>{ this.formatData( row, 'a5' ) }</td>
                        <td style={style}>{ this.formatData( row, 'a6' ) }</td>
                        <td>{ this.formatData( row, 'investment' ) }</td>
                    </tr>
                );
            }
        })
        console.log(data[0].dateofRetirement)
        return (
            
            <div className="caption">
                <h6>{newLabel} </h6>
                <div className="swipe">Swipe table horizontally to view more.</div>
                { !!disclaimer ? <div className="disclaimer">{disclaimer}</div> : null }
                <div className="table-wrap">
                    <table className={`table ${name}`}>
                        <thead>                
                            <tr>
                                <th></th>
                                <th>Hybrid A-5 Plan <br /><em className="small" >Your current plan</em></th>
                                <th>Hybrid A-6 Plan</th>
                                <th>Straight Investment Plan</th>
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state,props) => ({
    display: state.memberTabs.tab === '' || state.memberTabs.tab === props.name ? true : false,
    data: state.data.results[ props.data ]
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberResultsTable)