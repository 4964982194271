export const CHANGE_VALIDATION = 'CHANGE_VALIDATION'

const initialState = {
};

export default (state = initialState, action) => {
  switch(action.type) {
    case CHANGE_VALIDATION:
      return {
        ...state,
        ...action.newValidation
      };
    default: return state;
  }
}

export const changeValidation = (newValidation) => {
  return dispatch => {
    dispatch({
      type: CHANGE_VALIDATION,
      newValidation
    })
  }
}