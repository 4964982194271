import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class InputField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectionStart: 0
        }
        this.inputField = React.createRef();
        this.getSelectionStart = this.getSelectionStart.bind(this);
        this.checkMinMax = this.checkMinMax.bind(this);
        this.parseValue = this.parseValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.parseValue( this.props.value );
    }
    componentDidUpdate() {
        let { selectionStart } = this.state;
        const inputField = this.inputField.current;
        const val = inputField.value;
        const regex = /,/g
        while( regex.exec(val) ){
            if( regex.lastIndex <= selectionStart ) {
                selectionStart++;
            }
        }
        inputField.setSelectionRange(selectionStart,selectionStart)
    }
    getSelectionStart() {
        const inputField = this.inputField.current;
        let selectionStart = inputField.selectionStart;
        const val = inputField.value;
        const regex = /,/g
        while( regex.exec(val) ){
            if( regex.lastIndex <= selectionStart ) {
                selectionStart--;
            }
        }
        return selectionStart;
    }
    addCommas( val ){
        val += '';
        let x = val.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1,$2');
        }
        return x1 + x2;
    }
    // ensureFloat(val){
    //     if( val === "" ) val = 0;
    //     return parseFloat(val).toFixed(2);
    // }
    checkMinMax( val ) {
        if( this.props.hasOwnProperty('minVal') ) {
            if( val < this.props.minVal ) return this.props.minVal;
        }
        if( this.props.hasOwnProperty('maxVal') ) {
            if( val > this.props.maxVal ) return this.props.maxVal;
        }
        return val;
    }
    parseValue( val ) {
        val = this.checkMinMax( val )
        val += ''
        const replacements = {
            'monetary': {
                find:       /[^0-9]/g,
                replace:    ""
            }
        }
        const formats = {
            'monetary': this.addCommas,
            // 'percentage': this.ensureFloat
        }
        if( !!replacements[this.props.type] ) {
            val = val.replace(replacements[this.props.type].find,replacements[this.props.type].replace)
        }
        if( !!formats[this.props.type] ) {
            val = formats[this.props.type](val)
        }
        this.setState({
            selectionStart: this.getSelectionStart()
        })
        if( typeof val === "undefined" || !val || val === "undefined" ) val = ''        
        this.props.onChange( val );
    }
    handleChange( e ){
        const val = e.target.value;
        this.parseValue( val );
    }
    render() {
        return (
            <div className="input-wrap"><input ref={this.inputField} id={this.props.name} value={this.props.value} type="text" onChange={this.handleChange} /></div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    value: ownProps.value
})

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputField)