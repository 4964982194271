import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import data from '../data/data'

class StatusBar extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(e,name) {
    	e.preventDefault();
    	this.props.history.push( '/'+name );
    }
    render() {
    	const { currentNav } = this.props
		const screens = data.screens
		let buttons = []
		let navSet = false;
        let currentStep = 0;

		screens.forEach( (screen,key) => {
			let className = navSet ? 'future' : 'past'
			if( screen.name === currentNav ) {
				className = 'current'
				navSet = true
                currentStep = key+1
			}
			buttons.push(
				<a key={key} onClick={(e) => this.handleClick(e,screen.name)} href="/" className={className}><span>{navSet?(key+1):<i className="icon-ok"></i>}</span><strong>{!!screen.statusBarTitle ? screen.statusBarTitle : screen.title}</strong></a>
			)
		})

        return (
            <div className={"status-bar step-"+currentStep}><div className="container"><div className="container">{buttons}</div><div className="progress"></div></div></div>
        );
    }
}

const mapStateToProps = state => ({
	currentNav: state.nav.currentNav
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StatusBar)