export const SWITCH_TAB = 'SWITCH_TAB'

const initialState = {
  tab: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_TAB:
      return {
        ...state,
        tab: action.tab
      }

    default:
      return state
  }
}

export const switchTab = (tab) => {
  return dispatch => {
    dispatch({
      type: SWITCH_TAB,
      tab
    })
  }
}