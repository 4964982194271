import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash'

class ReviewField extends React.Component {
    render() {
        let fieldValue = this.props.value;
        const { fieldType, data } = this.props;
        if( typeof data!=="undefined" ) {
            fieldValue = get( fieldValue, data );
        }
        switch( fieldType ) {
            case 'datepicker' :
                // fieldValue = fieldValue.format("MM/DD/YYYY");
                const d = new Date(fieldValue)
                fieldValue = d.getMonth()+1 + "/" + d.getDate() + "/" + d.getFullYear(); 
                break;
            case 'monetary' :
                fieldValue = '$'+fieldValue;
                break;
            case 'percentage' :
                fieldValue = fieldValue+'%';
                break;
            default :
                break;
        }
        return (
            <span className="review-field">{fieldValue}</span>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReviewField)